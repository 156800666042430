import React from "react"
import Layout from '../layouts/layout'
import androidicon from "../imgs/app-stores/android-app-store.png"
import iosicon from "../imgs/app-stores/iOS-app-store.png"

const NotFound = () => {
  return (
    <Layout>

    <section className="home">
      <div className="home__header">

        {/* Site Title */}
        <h1 className="home__title">
          <span className="home__title-main">Check Back</span>
          <span className="home__title-sub">Later</span>
        </h1>

        <div className="home__app-box">

          {/* Android App Store */}
          <a href="https://play.google.com/store/apps/developer?id=Paperball+Studio+Games">
            <img className="home__app-store" src={androidicon} alt="Paperball Studio App Store Button for Android"/>
          </a>

          {/* iOS App Store */}
          <a href="https://apps.apple.com/us/developer/john-ramos/id1444746853">
            <img className="home__app-store" src={iosicon} alt="Paperball Studio App Store Button for iOS"/>
          </a>
          
        </div>
      </div>
    </section>

    </Layout>
  )
}

export default NotFound